:root {
  .assistant-send-icon-disabled {
    background-image: url('./blocknote/DisabledSendAIPromptIcon.svg');
    width: 44px;
    height: 44px;
    background-size: cover;
    }
}  

.dark {
  .assistant-send-icon-disabled {
    background-image: url('./blocknote/DisabledSendAIPromptIconDark.svg');
    width: 44px;
    height: 44px;
    background-size: cover;
  }
}