.alert_animation {
  animation-name: alert-side-in;
  animation-duration: 0.35s;
}

@keyframes alert-side-in {
  0% {
    transform: translateX(100%);
  }
  60% {
    transform: translateX(-15%);
  }
  80% {
    transform: translateX(5%);
  }
  0% {
    transform: translateX(0);
  }
}

.custom-toast-container {
  padding: 0;
}
.custom-toast-body {
  padding: 0;
}
