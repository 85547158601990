@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/index.css';

* {
  @apply transition-colors duration-200;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--quaternary-bg) var(--primary-bg); /* For Firefox */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: auto;
  /* font-family: "Inter"; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  @keyframes custom-pulse {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  .animate-custom-pulse {
    animation: custom-pulse 1.5s infinite;
  }
}

/* Global scrollbar styles */
* {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--bg-quaternary) var(--bg-primary); /* Example colors for Firefox */
}

*::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar */
  height: 8px; /* Thin horizontal scrollbar */
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bg-quaternary); /* Quaternary color */
  border-radius: 9999px; /* Fully rounded */
}

*::-webkit-scrollbar-track {
  background-color: var(--bg-primary); /* Primary color */
  border-radius: 9999px; /* Fully rounded */
}

*::-webkit-scrollbar-corner {
  background-color: var(--bg-quaternary);
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-25%); }
}
